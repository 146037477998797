import md5 from 'blueimp-md5';
import {
  API_HOST,
  DISABLE_AUTH,
  EMAIL_REGEX,
  SPECIAL_SIGNS,
  START_END_WITH_SPACE,
  TEMPLATE_STATUS,
  URL_REGEX,
  userIcon,
} from 'appConstants';

/**
 * Function to build version preview url
 * @param {string} templateId Unique Id of the template
 * @param {string} versionDate date, time and timezone
 * @returns {string} Returns version preview url
 */
export const buildVersionPreviewUrl = (templateId, versionDate) => {
  return `${API_HOST}/api/v2/usertemplate/public/${templateId}/version?versionDate=${versionDate}`;
};

/**
 * Function to show principal full name
 * @param {Array} userListId List of users id
 * @param {Array} selectedUserList selected users list
 */
export const getListUsersFullName = (
  userListId = [],
  selectedUserList = [],
) => {
  if (userListId) {
    return (
      userListId
        .filter(({ id }) => selectedUserList.includes(id))
        .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
        .join(selectedUserList.length === 0 ? '' : ', ') || ''
    );
  }

  return '';
};

/**
 * Function to build countdown git image url
 * @param {string} datetime date, time and timezone
 * @returns {string} Returns countdown git image url
 */
export const buildCountdownImageUrl = (datetime) => {
  return `${API_HOST}/api/v2/internal/countdown?eventDateTime=${datetime}`;
};

/**
 * Function to build shared template view url
 * @param {string} id Unique Id of the template
 * @returns {string} Returns shared template view url
 */
export const buildSharedTemplateViewUrl = (id) => {
  return `${API_HOST}/api/v2/usertemplate/sharing/show/${id}`;
};

/**
 * Check whether is empty or not
 * @param {object} obj Object ot check
 * @returns {boolean} Return true if object is empty
 */
export const isEmptyObject = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

/**
 * Function to build global asset url
 * @param {string} userId user principal id
 * @param {string} name Name of the image
 * @returns {string} Returns url of the image
 */
export const buildGlobalAssetsUrl = (userId, name = '') =>
  `${API_HOST}/api/v2/assets/user/${userId}/asset/${name}`;

/**
 * Function to build asset url
 * @param {string} id Id of the template
 * @param {string} name Name of the image
 * @returns {string} Returns url of the image
 */
export const buildAssetsUrl = (id, name = '') =>
  `${API_HOST}/api/v2/assets/template/${id}/asset/${name}`;

/**
 * Function to build screenshot image url
 * @param {string} id Id of the template
 * @param {number} lastModified Timestamp of last modified action
 * @returns {string} Returns url of the screenshot image
 */
export const buildScreenshotUrl = (id, lastModified) =>
  `${API_HOST}/resources/${id}/screenshot.jpg?${lastModified}`;

/**
 * Function to build preview url
 * @param {string} id Unique Id of the template
 * @returns {string} Returns preview template url
 */
export const buildPreviewUrl = (id) => `${API_HOST}/show/${id}`;

let lastId = 0;

export const generateId = (prefix = 'id') => {
  lastId++;
  return `${prefix}-${lastId}`;
};

/**
 * Function to check whether is string valid email or not
 * @param {string} email String to test whether it's email or not
 * @returns {boolean} Returns true if email is valid
 */
export const isEmail = (email) => {
  return EMAIL_REGEX.test(email);
};

/**
 * Function to check whether is string valid template name or not
 * @param {string} str String to test whether it doesntot consist special characters
 * @returns {boolean} Returns true if it is valid
 */
export const isStringWithSpecialSigns = (str) => {
  return START_END_WITH_SPACE.test(str) || SPECIAL_SIGNS.test(str);
};

/**
 * Function to check whether is string valid URL not
 * @param {string} url String to test whether it is url or simple string
 * @returns {boolean} Returns true if it is valid
 */
export const isValidURL = (url) => {
  return URL_REGEX.test(url);
};

/**
 * Function to check whether is element type is equal passed type
 * @param {object} element Dom element which should be checked
 * @param {string} type Type which should be checked
 * @returns {boolean} Returns true if component type is equal passed type
 */
export const checkComponentType = (element, type) => {
  if (typeof element !== 'object') {
    return false;
  }

  return element.dataset && element.dataset.type === type;
};

/**
 * Function to check whether template valid or not
 * @param {string} value Value to test
 * @returns {boolean} Returns true if it is valid
 */
export const isValidStatus = (status) => {
  return Object.values(TEMPLATE_STATUS).includes(status);
};

/**
 * Function to check whether auth disabled or not
 * @returns {boolean} Returns true if auth is disabled
 */
export const isDisableAuth = () => {
  return DISABLE_AUTH === 'true';
};

/**
 * Function for redirection to authenticate
 */
export const redirectToAuth = () => {
  window.location.replace('/not-found');
};

/**
 * Function to get cookie by name
 * @param {string} name Came of the cookie
 * @returns {string} Returns cookie value or undefined
 */
export const getCookie = (name) => {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');

  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
};

/**
 * Function to delete cookie by name
 * @param {string} name Name of the cookie
 */
export const deleteCookie = (name) => {
  document.cookie = `${name}=; Max-Age=-99999999;`;
};

/**
 * Function to get session id
 * @returns {string} Returns session id value
 */
export const getSessionId = () => {
  return !isDisableAuth()
    ? getCookie('sessionId')
    : localStorage.getItem('fakeSessionId') || 'session_id';
};

/**
 * Check whether error is abort error or not
 * @param {Error} error Error to check
 * @returns {boolean} Return true if error is AbortError
 */
export const isAbortError = (error) => {
  return error.name === 'AbortError';
};

/**
 * Function to get full name based on first and last names
 * @param {string} firstName First name
 * @param {string} lastName Last name
 * @returns {string} Full name or default name
 */
export const getFullName = ({ firstName, lastName } = {}) => {
  if (!firstName && !lastName) {
    return 'User';
  }

  if (!firstName && lastName) {
    return lastName;
  }

  if (!lastName && firstName) {
    return firstName;
  }

  return `${firstName} ${lastName}`;
};

/**
 * Function generate avatar url
 * @param {string} email Email of the user
 * @returns {string} Avatar url
 */
export const getAvatarUrl = (email) => {
  if (!email) {
    return userIcon();
  }

  const hash = md5(email.trim().toLocaleLowerCase());

  return `https://www.gravatar.com/avatar/${hash}`;
};

/**
 * Get error message from error object
 * @param {object} error Error object to extract error message
 * @param {string} [fallbackErrorMessage] Fallback error message in case there is no message in error
 * @returns {string} Return error message
 */
export function getErrorText(
  error,
  fallbackErrorMessage = 'Something went wrong. Try again later',
) {
  const fallback = fallbackErrorMessage;

  if (!error?.message) {
    return fallback;
  }

  return error.message;
}

/**
 * Check whether content or it's descendants has focus or not
 * @param {Element} content HTML element to check
 * @returns {boolean} Returns true if content or some element inside of it has focus
 */
export function contentHasFocus(content) {
  return (
    document.activeElement === content ||
    content.contains(document.activeElement)
  );
}

/**
 * Function to navigate the user to the URL in WayMore. It posts custom message.
 */
export const navigateToWayMoreUrl = (url = '') => {
  const postMessageObject = JSON.parse(
    JSON.stringify({
      type: 'navigatetourl',
      url,
    }),
  );

  if (window.location.ancestorOrigins.length > 0) {
    window.parent.postMessage(postMessageObject, '*');
  } else {
    window.location.replace(url);
  }
};

/**
 * Function to close embed editor in WayMore. It posts custom message.
 */
export const closeEmbedEditor = (templateId = '') => {
  const postMessageObject = JSON.parse(
    JSON.stringify({
      type: 'closeembededitor',
      templateid: templateId,
    }),
  );

  window.parent.postMessage(postMessageObject, '*');
};

/**
 * Function to send saved template status message to WayMore.
 */
export const templateSaveStatus = (status = false) => {
  const postMessageObject = JSON.parse(
    JSON.stringify({
      type: 'templatesavestatus',
      status,
    }),
  );

  if (window.location.ancestorOrigins.length > 0) {
    window.parent.postMessage(postMessageObject, '*');
  }
};

/**
 * Build query param
 * @param {Object} params Object with param name as key and value
 * @returns {String} Returns query param string if there are some params
 */
export const buildQueryString = (params = {}) => {
  const queryString = Object.keys(params)
    .reduce((acc, name) => {
      if (params[name]) {
        acc.push(`${name}=${params[name]}`);
      }

      return acc;
    }, [])
    .join('&');

  if (queryString) {
    return `?${queryString}`;
  }

  return '';
};
