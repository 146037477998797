import React from 'react';
import './NotFound.sass';

const NotFound = () => {
  return (
    <div className="dnd-not-found">
      <div className="dnd-not-found__wrapper">
        <img
          src="https://www.waymore.io/wp-content/uploads/2021/08/girl1copy-optimized.png"
          alt="404"
        />
        <div>
          <h1>No way</h1>
          <p>that the page you are looking for actually exists!</p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
